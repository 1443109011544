import React from 'react';

import { getCommentById, saveComment } from '../../../../networking/NetworkingComments';
import { formatValidInput } from '../../../../helpers/FormatHelper';
import { validate } from '../../../../helpers/ValidateHelper';

import AlertBox from '../../../shared/AlertBox';
import LoadingBox from '../../../shared/LoadingBox';

export default class CommentsScreen extends React.Component {

    state = {
        currentComment: '',
        listComments: [],
        validations: {
            comments: true
        }
    }

    componentDidMount() { 
        console.log("componentDidMount")
        console.log(this.props)
        this.loadCustomerContacts()
    }

    componentDidUpdate(prevProps) {
        console.log("componentDidMount")
        console.log(prevProps)
        if (this.props.customer.CustomerID !== prevProps.customer.CustomerID) {
            this.loadCustomerContacts()
        }
    }

    onChangeCurrenctComment = (event) => {
        this.setState({
            currentComment: event.target.value
        });
    }

    addComment = (event) => {

        let userName = localStorage.getItem('UserName');
        let comments = this.state.listComments || []

        var model = {
            PersonId: this.props.customer.PersonID,
            Content: this.state.currentComment,
            User: userName,
            CommentType: "Normal"
        }

        if (!this.validateComments()) {
            return
        }

        this.setState({ loading: true });
        saveComment(model).then(response => {
            console.log(response)
            if (response.Comment !== undefined && response.Comment.length === 1) {
                this.setState({
                    showAlert: true, alertTitle: 'Success', alertMessage: 'Comment successfully saved.', currentComment: ''
                });
            } else {
                var errorText = "";
                if (response.Message && response.Message !== "") {
                    errorText = response.Message;
                }

                if (response.ErrorMessage && response.ErrorMessage !== "") {
                    errorText = response.ErrorMessage;
                }

                if (errorText === "") {
                    errorText = "Something went wrong. Please try again later."
                }

                this.setState({
                    showAlert: true, alertTitle: 'Error', alertMessage: errorText, loading: false
                });
            }
        }).finally(x => {
            this.setState({ loading: false });
            this.loadCustomerContacts();
        })
    }

    loadCustomerContacts = () => {
        console.log("loadCustomerContacts")
        console.log(this.props.customer.CustomerID)
        if (this.props.customer.CustomerID > 0)
            getCommentById(this.props.customer.PersonID, 'Normal').then(response => {
                console.log(response)
                if (response.comments !== undefined) {
                    let comments = response.comments || []
                    let listComments = comments.map(x => x.Content + ' - ' + x.User + ' - ' + x['Comment Date'])
                    this.setState(
                        {
                            listComments: listComments
                        }
                    )
                }
            }
            )
    }

    closeAlert = () => {
        this.setState({ showAlert: false });
    }

    validateComments = () => {
        const fields = [
            { displayName: 'Comment', validateName: 'comments', value: this.state.currentComment, type: 'string' }
        ];

        const [valid, validations, errors] = validate(fields);

        this.setState({ validations: validations });

        if (!valid) {
            this.setState({
                showAlert: true, alertTitle: 'Error', alertMessage: 'Please fill all mandatory fields:', errors
            });
        }

        return valid;
    }

    render() {
        return (
            <div>
                <div className="" style={{ alignItems: 'center', display: 'flex', border: '1px solid #9DA99E' }}>

                    <div className="uk-margin uk-width-1-3">
                        <p style={{ marginTop: 0 }}>To add a new comment, type in the box below and press Add when done.</p>
                        <div class="uk-flex">
                            <div className="uk-width-2-3">
                                <textarea className={`uk-textarea ${formatValidInput(this.state.validations.comments)}`} 
                                    style={{ fontSize: 16, height: 70, marginRight: 10, overflowY: 'scroll' }}
                                    value={this.state.currentComment}
                                    onChange={this.onChangeCurrenctComment}
                                >{this.state.currentComment}</textarea>
                            </div>
                            <div className="uk-width-1-3" style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <button className="uk-button uk-button-green uk-width-1-2" style={{ marginTop: 10 }} onClick={this.addComment}>Add</button>
                            </div>
                        </div>
                    </div>

                    <div className="uk-width-2-3 uk-form-stacked" style={{ overflowY: 'scroll', paddingLeft: 0, borderLeft: '1px solid #9DA99E' }} >
                        <table className="uk-table uk-table-hover uk-table-divider" style={{ marginBottom: 0 }}>
                            <tbody>
                                {this.state.listComments.map((item, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>
                                                {item}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                    
                </div>
                <AlertBox open={this.state.showAlert} onClose={this.closeAlert} title={this.state.alertTitle} message={this.state.alertMessage}
                    messageBoldList={this.state.errors} type="Ok" okClick={this.closeAlert} />
                <LoadingBox loading={this.state.loading} />
            </div>
        );
    }
}