import React, { useContext, useEffect, useRef, useState } from 'react';
import AppContext from '../../AppContext';
import { getCommentById, saveComment } from '../../networking/NetworkingComments';
import NewEditableGrid from './NewEditableGrid';
import AlertBox from './AlertBox';

const Comments = (props) => {
    const context = useRef(useContext(AppContext));
    const [comments, setComments] = useState([]);
    const [reloadComments, setReloadComments] = useState(false);
    
    const [showAlert, setShowAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    
    const [columnDetail, setColumnDetail] = useState({});

    useEffect(() =>{
        const loadComments = async () => {
            context.current.startLoading();
            const json = await getCommentById(props.customer.PersonID, props.commentType);
            context.current.finishLoading();
            if(json !== null && json !== undefined){
                if(json.comments)
                {
                setComments(json.comments);
                }
                if(json.columnDetail)
                {
                setColumnDetail(JSON.parse(json.columnDetail))
                }
            } else {
                setComments([])
            }
        };
        if(props.customer && props.customer.PersonID)
        {
            loadComments();
        }
    },[reloadComments, props.customer, props.commentType]);

    const closeAlert = () => {
        setShowAlert(false);
    };
    
    const saveCommentGrid = async (record, row) => {
        let userName = localStorage.getItem('UserName');
        //FeeStructureId: currentFeeID,
        const model = {
            Content: row['Content'],
            PersonId: props.customer.PersonID,
            User: userName,
            CommentType: props.commentType
        }
        context.current.startLoading();
        await saveComment(model);
        context.current.finishLoading();
        setReloadComments(!reloadComments);
    }
    
    const deleteComment = async (record, row) => {
     /*   context.current.startLoading();
        var json = await deleteFee(record.ID);
        context.current.finishLoading();
        
        if(json !== null){
            if (json.httpStatusCode !== 200){
                setShowAlert(true);
                setAlertTitle('Error');
                if (json.Message != null && json.Message !== '') {
                    setAlertMessage(json.Message);
                } else {
                    setAlertMessage(json.httpErrorMessage);
                }
                setReloadComments(!reloadComments);
            } else {
                setShowAlert(true);
                setAlertTitle('Success');
                setAlertMessage('Fee successfully removed.');
                setReloadComments(!reloadComments);
            }
        }*/
    }

    return (
        <div>            
                <NewEditableGrid
                        data={comments}
                        columnDetails={columnDetail}
                        isEditable={false}
                        canAdd={true}
                        addSaveFunction={saveCommentGrid}
                        useDeleteButton={false}
                        deleteFunction={deleteComment}
                        useExportButton={true}
                        tableName="Comments"
                        useIcons={true} />
        
            
            <AlertBox id="alert-error" open={showAlert} onClose={closeAlert} title={alertTitle} message={alertMessage} type="Ok" okClick={closeAlert} />
        </div>
    )
}

export default Comments