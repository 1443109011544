import { PlusOutlined, SearchOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Form, Input, InputNumber, Popconfirm, Select, Space, Table, Typography, Tooltip } from 'antd';
import 'antd/dist/antd.css';
import Text from 'antd/lib/typography/Text';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import AppContext from '../../../AppContext';
import { formatToCurrencyWithScale } from '../../../helpers/FormatHelper';
import { getCurrencies } from '../../../networking/NetworkingCurrencies';
import { addForward, deleteForward, getForwardbyCategoryID } from '../../../networking/NetworkingPricing';
import { getStyle } from '../../../styles/styles';
import AlertBox from '../../shared/AlertBox';
const style = getStyle();
const { Option } = Select;

const NewForwardPricing = ({ categoryID, onAddOrDeleteSpotPricing }) => {
    const context = useRef(useContext(AppContext));
    const [form] = Form.useForm();
    const [forwardPricing, setForwardPricing] = useState([]);
    const [reloadForward, setReloadForward] = useState(false);

    const [editingKey, setEditingKey] = useState(0);
    const [currentPage, setCurrentPage] = useState();

    const [filteringSomething, setFilteringSomething] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [searchArray, setSearchArray] = useState({});
    const searchInput = useRef();

    const [currencies, setCurrencies] = useState([]);
    const [currentPricingCategoryDetailID, setCurrentPricingCategoryDetailID] = useState(0);
    const [currentUpdateToken, setCurrentUpdateToken] = useState('');

    const [showAlert, setShowAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState('');
    const [alertMessage, setAlertMessage] = useState('');

    useEffect(() => {
        const loadForwardPricing = async () => {
            context.current.startLoading();
            const json = await getForwardbyCategoryID(categoryID);
            context.current.finishLoading();
            if (json != null && json.forwardPricingDetails != null) {
                const forwardDetails = json.forwardPricingDetails.map((d) => ({...d, key: `ForwardPricingDetails-${d.PricingCategoryDetailID}`}));
                setForwardPricing(forwardDetails);
            } else {
                setForwardPricing([]);
            }
        };
        loadForwardPricing();
    }, [reloadForward, categoryID]);

    useEffect(() => {
        const loadCurrencies = async () => {
            context.current.startLoading();
            const json = await getCurrencies(0, 0);
            context.current.finishLoading();
            if (json != null && json.currencies != null) {
                const currencies = json.currencies.map((c) => ({...c, key: `ForwardPricing-${c['Currency Id']}`}));
                setCurrencies(currencies);
            }
        };
        loadCurrencies();
    }, []);

    const isEditing = (record) => record.PricingCategoryDetailID === editingKey;

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
        setFilteringSomething(true);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
        setFilteringSomething(false);
    };

    const settingSearchStateValues = (value, col) => {
        if (value != null) {
            setSearchText(value);
            setSearchedColumn(col);
            setSearchArray(...(searchArray[col] = value));
            setFilteringSomething(true);
        }
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        searchInput.current = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => {
                        setSelectedKeys(e.target.value ? [e.target.value] : []);
                    }}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        width: 188,
                        marginBottom: 8,
                        display: 'block'
                    }}
                />
                <Space>
                    <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} icon={<SearchOutlined />} size="small" style={{ width: 90 }}>
                        Search
                    </Button>
                    <Button
                        onClick={() => {
                            handleReset(clearFilters);
                        }}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            settingSearchStateValues(selectedKeys[0], dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) => (record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : ''),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            )
    });

    const save = async (record) => {
        try {
            const row = await form.validateFields();
            const newData = [...forwardPricing];
            console.log(row);
            const index = newData.findIndex((item) => record['currentPricingCategoryDetailID'] === item['currentPricingCategoryDetailID']);
            if (index > -1) {
                const userID = localStorage.getItem('UserID');
                const model = {
                    Period: row['Period'],
                    PricingCategoryDetailID: forwardPricing[index]['currentPricingCategoryDetailID'] !== 0 ? currentPricingCategoryDetailID : 0,
                    CategoryID: categoryID,
                    HomeCurrencyCode: row['Home Currency'],
                    MarkupFactor: row['Markup Factor'],
                    ForeignCurrencyCode: row['Foreign Currency'],
                    TransAmountFrom: row['Amount From'],
                    TransAmountTo: row['Amount To'],
                    PercentOrPoints: row['Is Percent or Point?'],
                    userID: userID,
                    updateToken: currentUpdateToken
                };
                context.current.startLoading();
                const json = await addForward(model);
                context.current.finishLoading();
                if (json != null) {
                    if (json.httpStatusCode !== 200) {
                        setShowAlert(true);
                        setAlertTitle('Error');
                        setAlertMessage(json.httpErrorMessage);
                        setReloadForward(!reloadForward);
                        setEditingKey(0);
                    } else {
                        setShowAlert(true);
                        setAlertTitle('Success');
                        setAlertMessage('Forward Pricing successfully saved.');
                        setReloadForward(!reloadForward);
                        setEditingKey(0);
                        setCurrentPricingCategoryDetailID(json.pricingCategoryDetailID);
                        setCurrentUpdateToken(json.updateToken);
                        onAddOrDeleteSpotPricing();
                    }
                }
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const deleteRecord = async (record) => {
        const userID = localStorage.getItem('UserID');
        context.current.startLoading();
        const json = await deleteForward(record['PricingCategoryDetailID'], userID);
        context.current.finishLoading();
        if (json != null) {
            if (json.httpStatusCode !== 200) {
                setShowAlert(true);
                setAlertTitle('Error');
                setAlertMessage(json.httpErrorMessage);
                setReloadForward(!reloadForward);
            } else {
                setShowAlert(true);
                setAlertTitle('Success');
                setAlertMessage('Forward Pricing successfully removed.');
                setReloadForward(!reloadForward);
                onAddOrDeleteSpotPricing();
            }
        }
    };

    const columns = [
        {
            title: 'Home Currency',
            dataIndex: 'Home Currency',
            width: '20%',
            editable: true,
            align: 'left',
            sorter: (a, b) => {
                return a['Home Currency'].localeCompare(b['Home Currency']);
            },
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('Home Currency')
        },
        {
            title: 'Foreign Currency',
            dataIndex: 'Foreign Currency',
            width: '20%',
            editable: true,
            align: 'left',
            sorter: (a, b) => {
                return a['Foreign Currency'].localeCompare(b['Foreign Currency']);
            },
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('Foreign Currency')
        },
        {
            title: 'Lower Limit',
            dataIndex: 'Amount From',
            width: '10%',
            editable: true,
            align: 'center',
            sorter: (a, b) => a['Amount From'] - b['Amount From'],
            sortDirections: ['descend', 'ascend'],
            render: (_, record) => <Text>{formatToCurrencyWithScale(record['Amount From'], record['Foreign Currency'])}</Text>
        },
        {
            title: 'Upper Limit',
            dataIndex: 'Amount To',
            width: '10%',
            editable: true,
            align: 'center',
            sorter: (a, b) => a['Amount To'] - b['Amount To'],
            sortDirections: ['descend', 'ascend'],
            render: (_, record) => <Text>{formatToCurrencyWithScale(record['Amount To'], record['Foreign Currency'])}</Text>
        },
        {
            title: 'Markup Factor',
            dataIndex: 'Markup Factor',
            width: '10%',
            editable: true,
            align: 'center',
            sorter: (a, b) => a['Markup Factor'] - b['Markup Factor'],
            sortDirections: ['descend', 'ascend']
        },
        {
            title: 'Period',
            dataIndex: 'Period',
            width: '10%',
            editable: true,
            align: 'center',
            sorter: (a, b) => a['Period'] - b['Period'],
            sortDirections: ['descend', 'ascend']
        },
        {
            title: 'Is Percent or Point?',
            dataIndex: 'Is Percent or Point?',
            width: '15%',
            editable: true,
            align: 'center',
            sorter: (a, b) => {
                return a['Is Percent or Point?'].localeCompare(b['Is Percent or Point?']);
            },
            sortDirections: ['descend', 'ascend']
        },
        {
            title: 'Actions',
            dataIndex: 'Actions',
            align: 'center',
            width: '15%',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <a onClick={() => save(record.PricingCategoryDetailID)} style={{ marginRight: 8 }}>
                            Save
                        </a>
                        <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                            <a>Cancel</a>
                        </Popconfirm>
                    </span>
                ) : (
                    <div>
                        <Space size="middle">
                            <Tooltip title="Edit">
                                <Typography.Link disabled={editingKey !== 0 || forwardPricing[0]['PricingCategoryDetailID'] === 0} onClick={() => edit(record)}>
                                    <EditOutlined />
                                </Typography.Link>
                            </Tooltip>
                            <Popconfirm title="Sure to delete?" onConfirm={() => deleteRecord(record)}>
                                <Tooltip title="Delete">
                                    <a disabled={editingKey !== 0 || forwardPricing[0]['PricingCategoryDetailID'] === 0}><DeleteOutlined/></a>
                                </Tooltip>
                            </Popconfirm>
                        </Space>
                    </div>
                );
            }
        }
    ];

    const edit = (record) => {
        if (forwardPricing[0].PricingCategoryDetailID === '') {
            let auxCountries = [...forwardPricing];
            auxCountries.shift();
            setForwardPricing(auxCountries);
        }

        form.setFieldsValue({
            UpdateToken: '',
            ...record
        });
        setEditingKey(record.PricingCategoryDetailID);
        setCurrentPricingCategoryDetailID(record.PricingCategoryDetailID);
        setCurrentUpdateToken(record.UpdateToken);
    };

    const cancel = (page) => {
        setEditingKey(0);
        if (typeof page === 'number') {
            setCurrentPage(page);
        }

        if (forwardPricing[0].PricingCategoryDetailID === 0) {
            let auxforwardPricing = [...forwardPricing];
            auxforwardPricing.shift();
            setForwardPricing(auxforwardPricing);
        }
    };

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        let type = '';

        switch (col.dataIndex) {
            case 'Amount From':
            case 'Amount To':
            case 'Markup Factor':
            case 'Period': {
                type = 'number';
                break;
            }
            case 'Status': {
                type = 'check';
                break;
            }
            case 'Home Currency':
            case 'Foreign Currency':
            case 'Is Percent or Point?': {
                type = 'select';
                break;
            }
            default: {
                type = 'text';
                break;
            }
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: type,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record)
            })
        };
    });

    const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
        let inputNode = null;
        switch (inputType) {
            case 'number': {
                switch(title) {
                    default:
                    case 'Period': {
                        inputNode = <InputNumber min={0} style={{ width: '100%', textAlignLast: 'center' }} />;
                        break;
                    }
                    case 'Lower Limit':
                    case 'Upper Limit': {
                        inputNode = <InputNumber min={0} style={{ width: '100%', textAlignLast: 'center' }} />;
                    }
                }
                break;
            }
            case 'select': {
                switch (title) {
                    default:
                    case 'Home Currency':
                    case 'Foreign Currency': {
                        inputNode = (
                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                placeholder="Select a Currency"
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                                {currencies.map((currency) => (
                                    <Option
                                        key={`ForwardPricing-options-${currency['Currency Id']}`}
                                        value={currency['Currency Id']}>{currency['Currency Id'] + ' - ' + currency['Currency Name']}
                                    </Option>
                                ))}
                            </Select>
                        );
                        break;
                    }
                    case 'Is Percent or Point?': {
                        inputNode = (
                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                placeholder="Select an Item"
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                                <Option key='ForwardPricing-option-Percent' value={'PERCENT'}>Percent</Option>,
                                <Option key='ForwardPricing-option-Points' value={'POINTS'}>Points</Option>
                            </Select>
                        );
                        break;
                    }
                }
                break;
            }
            default: {
                inputNode = <Input style={{ textAlign: 'center' }} />;
                break;
            }
        }

        let styleEdit = { margin: 0 };

        if ((record !== undefined && record.isNew !== undefined && record.isNew === true) || editing) {
            styleEdit = {
                paddingBottom: 10,
                paddingTop: 10,
                margin: 0
            };
        }

        const getCustomRules = (columnDataIndex) => {
            switch (columnDataIndex) {
                case 'Markup Factor': {
                    return [{
                        min: 0,
                        type: 'number',
                        mesage: 'Please Input a valid number!'
                    }]
                }
                case 'Amount From': {
                    return [{
                        min: 0,
                        type: 'number',
                        mesage: 'Please Input a valid number!'
                    }]
                }
                case 'Amount To': {
                    return [
                        {
                            min: 0,
                            type: 'number',
                            mesage: 'Please Input a valid number!'
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (getFieldValue('Amount From') <= value) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error('Lower Limit should be less than or equal to Upper Limit!'));
                            },
                          }),
                    ];
                }
                case 'Period': {
                    return [{
                        min: 0,
                        type: 'number',
                        mesage: 'Please Input a valid number!'
                    }]
                }
                default: return [
                    {
                        required: true,
                        message: `Please Input a ${title}!`
                    }
                ];
            }
        }
        const rules = getCustomRules(dataIndex);

        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item name={dataIndex} style={styleEdit} rules={rules}>
                        {inputNode}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    };

    const addNewForwardPricing = () => {
        let actual = [...forwardPricing];
        if (actual[0] != null && actual[0]['PricingCategoryDetailID'] === 0) {
            return;
        } else {
            actual.unshift({
                'key': 'ForwardPricingDetails-new',
                'PricingCategoryDetailID': 0,
                'Home Currency': '',
                'Foreign Currency': '',
                'Amount From': 0,
                'Amount To': 0,
                'Markup Factor': 0,
                'Period': 0,
                'Is Percent or Point?': 'PERCENT',
                UpdateToken: '',
                isNew: true
            });
            setForwardPricing(actual);
            setCurrentPage(1);
            form.setFieldsValue({
                'Home Currency': '',
                'Foreign Currency': '',
                'Amount From': 0,
                'Amount To': 0,
                'Markup Factor': 0,
                'Period': 0,
                'Is Percent or Point?': 'PERCENT',
                ...actual
            });
            setCurrentUpdateToken('');
            setCurrentPricingCategoryDetailID(0);
        }
    };

    const closeAlert = () => {
        setShowAlert(false);
    };

    function itemRender(current, type, originalElement) {
        if (type === 'prev') {
            return <a>Previous</a>;
        }
        if (type === 'next') {
            return <a>Next</a>;
        }
        return originalElement;
    }

    return (
        <div>
            <h3 className="uk-heading-divider" style={style.titleStyle}>Forward Pricing</h3>
            <Form form={form} component={false}>
            <Table
                components={{ body: { cell: EditableCell } }}
                dataSource={forwardPricing}
                columns={mergedColumns}
                size='small'
                pagination={{
                    onChange: cancel,
                    showQuickJumper: true,
                    itemRender: itemRender,
                    current: currentPage,
                    defaultCurrent: 1,
                    size: 'default'
                }}
                footer={() => (
                    <Button
                        type="dashed"
                        onClick={addNewForwardPricing}
                        block
                        icon={<PlusOutlined />}
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        disabled={editingKey !== 0 || filteringSomething || (forwardPricing[0] !== undefined && forwardPricing[0]['PricingCategoryDetailID'] === 0)}
                    >
                        Add a Forward Pricing
                    </Button>
                )}/>
            </Form>
            <AlertBox id="alert-pricing" open={showAlert} onClose={closeAlert} title={alertTitle} message={alertMessage} type="Ok" okClick={closeAlert} />
        </div>
    );
}

export default NewForwardPricing;
