import React from 'react';
import Comments from '../../../shared/Comments';


const NewCommentsScreen = (props) => {

    return (
        <div>   
            <Comments customer = {props.customer} commentType={'Normal'} />
        </div>
    )
}

export default NewCommentsScreen